<!--  -->
<template>
  <div class="">
    <el-drawer
      :title="$t('c643')"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      size="845px"
    >
      <div class="content" v-loading="getDataLoding">
        <div class="headBox">
          <div class="accountImg">
            <img
              :src="`data:image/png;base64,${postData.socialmediapicture}`"
              v-if="postData.socialmediapicture"
              class="mainImg"
            />
            <svg aria-hidden="true" v-else class="mainImg">
              <use href="#icon-contacts_def"></use>
            </svg>
            <!-- 平台图片 -->
            <svg
              class="inimg"
              aria-hidden="true"
              v-show="postData.socialtype === 'facebook'"
            >
              <use href="#icon-facebook_white"></use>
            </svg>
            <svg
              class="inimg"
              aria-hidden="true"
              v-show="postData.socialtype === 'twitter'"
            >
              <use href="#icon-twitter_white"></use>
            </svg>
            <svg
              class="inimg"
              aria-hidden="true"
              v-show="postData.socialtype === 'linkedIn'"
            >
              <use href="#icon-linkedIn_white"></use>
            </svg>
            <span>{{ socialmedianame }}</span>
          </div>
          <div class="action-dropdown">
            <el-dropdown class="action-dropdown" @command="action">
              <el-button size="mini">
                {{$t('label.import.index.operation')}}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ action: 'view' }">{{
                  viewName
                }}</el-dropdown-item>
                <el-dropdown-item :command="{ action: 'clone' }">
                  {{$t('pagecreator.page.button.copy')}}
                </el-dropdown-item>
                <el-dropdown-item :command="{ action: 'delete' }">
                  {{$t('label.department.user.delete')}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="post-content" v-html="textcontent"></div>
        <div class="inner">
          <div v-for="img in filecontentid" :key="img">
            <div class="imgColumn" v-show="iconShow">
              <i class="el-icon-delete" @click="deleteImg()"></i
              ><i class="el-icon-edit" @click="editImg()"></i>
            </div>
            <img
              class="editorImg"
              :style="`height:${250 / filecontentid.length}px;width:${
                300 / filecontentid.length
              }px`"
              :src="imgSrc(img)"
            />
          </div>
        </div>

        <div class="before-box">
          <span
            >
            <!-- Published at -->
            {{ $t("label.when")}}: <span class="beforeAt">{{ senddate }}</span></span
          >
          <span
            >
            <!-- Created by -->
            {{$t("label.createby")}}: <span class="beforeAt">{{ username }}</span></span
          >
          <span>{{$t('c703')}}: <span class="beforeAt">cloudcc</span></span>
        </div>
        <div class="learn-box boder-bot" v-if="this.poststype !== 'fail'">
          <div>
            {{$t('label.marketingactivity')}} :<a
              @click="routerToCampaign(campaignname)"
              :class="campaignname ? 'linkCam' : 'linkCamNo'"
            >
              {{ campaignname ? campaignname : "none" }}</a
            >
          </div>
          <el-button
            type="primary"
            size="mini"
            class="marLeft-30px"
            @click="showCampaign"
            >{{ campaignname ? $i18n.t('label.department.user.edit') : "Add" }}</el-button
          >
        </div>
        <div class="like-box" v-if="this.poststype === 'sending'">
          <div
            v-for="(item, index) in likeList"
            :key="index"
            class="statis_box"
          >
            <div>{{ item.name }}</div>
            <div class="statis_number">{{ item.number }}</div>
          </div>
        </div>
        <div class="buttom_text">
           {{$t('c622')}} {{ refDate }}
        </div>
      </div>
    </el-drawer>
    <el-dialog
      :visible.sync="selectVisible"
      :title="$t('c701')"
      top="15%"
      width="40%"
      :close-on-click-modal="true"
      :before-close="handleCampaginClose"
    >
      <div class="campaign-mes">
        <!-- Campaign -->
        {{$t('label.marketingactivity')}}
        </div>
      <div class="campaign-select">
        <el-select style="width: 100%" v-model="campaignSelect" filterable :clearable="true">
          <el-option
            style="max-width: 600px"
            v-for="(item, no) in campaignList"
            :key="no"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectVisible = false" size="mini">
          <!-- drafts -->
          <!-- Cancel -->
          {{$t('label.emailtocloudcc.button.back')}}
        </el-button>
        <el-button type="primary" size="mini" @click="saveCampaign">
          <!-- changes -->
          <!-- Save -->
          {{$t('label.chatter.save')}}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as marketChannel from "./api";
export default {
  components: {},
  data() {
    return {
      postshomepage: "", // 第三方页
      campaignSelect: "",
      selectVisible: false,
      refDate: Date(),
      viewName: "",
      token: this.$CCDK.CCToken.getToken(),
      getDataLoding: false,
      likeList: [],
      direction: "rtl",
      textcontent: "",
      campaignname: "",
      drawer: false,
      createdate: "",
      senddate: "",
      username: "",
      socialmedianame: "",
      postid: "",
      filecontentid: "",
      socialtype: "",
      poststype: "",
      campaignList: [],
      postData: {},
    };
  },
  //方法集合
  methods: {
    reList() {
      this.$emit("reList");
    },
    routerToCampaign(campaignname) {
      if (campaignname) {
        this.$router.push({
          path: `/commonObjects/detail/${this.campaignSelect}/DETAIL`,
        });
      }
    },
    showCampaign() {
      marketChannel
        .getScoialMarketingActivity({
          orgid: this.$store.state.userInfoObj.orgId,
        })
        .then((res) => {
          if (res.result) {
            this.campaignList = res.data;
            this.selectVisible = true;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    saveCampaign() {
      marketChannel
        .updateBatchPostCampaign({
          orgid: this.$store.state.userInfoObj.orgId,
          postsids: this.postid,
          campaignid: this.campaignSelect,
        })
        .then((res) => {
          if (res.result) {
            // "修改成功"
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("vue_label_notice_modify_success"),
            });
            this.detailSoical({ id: this.postid });
            this.reList();
            this.selectVisible = false;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    handleCampaginClose() {
      this.selectVisible = false;
    },

    //获取图片
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/chatterfile.action?m=previewSocial&fileid=${id}&binding=${this.token}`;
    },
    action(obj) {
      if (obj.action === "view") {
        window.open(this.postshomepage);
      } else if (obj.action === "delete") {
        this.deleteSelect();
      } else if (obj.action === "clone") {
        this.postClone();
      }
    },
    // 帖子克隆
    
    postClone() {
      this.$emit("openPost", "clone", this.postData);
      this.drawer = false;
    },
    
    async deleteSelect() {
      marketChannel
        .delPostInfo({
          orgid: this.$store.state.userInfoObj.orgId,
          postsid: this.postid,
        })
        .then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label.tabpage.delsuccessz"),
            });
            this.drawer = false;
            this.reList();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    imgListSpilt(fileid) {
      if (fileid) {
        let idArray = fileid.split(",");
        return idArray;
      } else {
        return [];
      }
    },
    // 代开detail页
    
    detailSoical(row) {
      this.getDataLoding = true;
      this.postid = row.id;
      marketChannel
        .getPostInfoDetail({
          orgid: this.$store.state.userInfoObj.orgId,
          postsid: row.id,
        })
        .then((res) => {
          if (res.result) {
            this.postData = res.data.postdata;
            this.campaignname = res.data.postdata.campaignname;
            this.textcontent = res.data.postdata.textcontent;
            this.createdate = res.data.postdata.createdate;
            this.senddate = res.data.postdata.senddate;
            this.username = res.data.postdata.username;
            this.campaignSelect = res.data.postdata.campaignid;
            this.socialmedianame = res.data.postdata.socialmedianame;
            this.postshomepage = res.data.postdata.postshomepage;
            this.filecontentid = this.imgListSpilt(
              res.data.postdata.filecontentids
            );
            this.socialtype = res.data.postdata.socialtype;
            this.poststype = res.data.postdata.poststype;
            let date = new Date(
              res.data.postdata.createdate.replace("/-/g", "/")
            );
            this.refDate = date.toString().substr(0, 21);
            if (this.socialtype === "linkedIn") {
              this.viewName = this.$i18n.t('c630');
              this.likeList = [
                {
                  // name: "LIKES",
                  name: this.$i18n.t("c809"),
                  number: res.data.likes,
                },
                {
                  // name: "COMMENTS",
                   name: this.$i18n.t("c812"),
                  number: res.data.replies,
                },
                {
                  // name: "SHARE",
                   name: this.$i18n.t("c813"),
                  number: res.data.retweets,
                },
              ];
            } else if (this.socialtype === "facebook") {
              this.viewName = this.$i18n.t('c632');
              this.likeList = [
                {
                  // name: "LIKES",
                   name: this.$i18n.t("c809"),
                  number: res.data.likes,
                },
                {
                  // name: "COMMENTS",
                   name: this.$i18n.t("c812"),
                  number: res.data.replies,
                },
                {
                  // name: "SHARE",
                   name: this.$i18n.t("c813"),
                  number: res.data.retweets,
                },
              ];
            } else {
              this.viewName = this.$i18n.t('c631');
              this.likeList = [
                {
                  // name: "LIKES",
                   name: this.$i18n.t("c809"),
                  number: res.data.likes,
                },
                {
                  // name: "REPLIES",
                   name: this.$i18n.t("c810"),
                  number: res.data.replies,
                },
                {
                  // name: "RETWEETS",
                   name: this.$i18n.t("c811"),
                  number: res.data.retweets,
                },
              ];
            }
            this.getDataLoding = false;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      this.drawer = true;
    },
    handleClose() {
      this.selectVisible = false;
      this.drawer = false;
    },
  },
};
</script>
<style lang='scss' scoped>
.headBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inner {
  justify-content: center;
  display: flex;
  .imgColumn {
    display: flex;
    justify-content: space-around;
    position: absolute;
    width: 50px;
    top: -5px;
    left: 250px;
    i {
      cursor: pointer;
    }
  }
  .editorImg {
    margin: 10px 0px;
  }
  .editorImg:hover {
    box-shadow: 2px 2px 2px 2px #ccc;
  }
}
.campaign-mes {
  height: 60px;
  line-height: 70px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
}
.campaign-remind {
  background: #f8fcff;
  border: 1px solid #006dcc;
  border-radius: 2px;
  border-radius: 2px;
  padding: 20px 50px 20px 15px;
  margin-top: 25px;
}
.detail-img {
  .editorImgPreview {
    margin: 10px 0px;
    height: 250px;
    width: 300px;
  }
}
.like-box {
  display: flex;
  justify-content: space-around;
  .statis_box {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: center;
    .statis_number {
      margin: 0px auto;
      font-size: 28px;
    }
  }
}
.buttom_text {
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  // position: fixed;
  // bottom: 20px;
}
.icon {
  display: inline-block;
}
.inimg {
  width: 40px;
  height: 40px;
}
::v-deep .el-drawer__body {
  background: #f0f0f0;
}
::v-deep .el-drawer__header {
  background: linear-gradient(90deg, #2d6cfc 0%, #6c98fd 100%);
  font-family: Helvetica;
  font-size: 18px;
  color: #fff;
  letter-spacing: 0;
  font-weight: bold;
  padding: 20px;
  margin-bottom: 0px;
}
.post-content {
  margin: 10px auto;
  width: 100%;
  word-wrap: break-word;
}
.content {
  padding: 20px 30px 0px 30px;
  .time-box {
    height: 50px;
    line-height: 50px;
  }
  .comments-box {
    display: flex;
    justify-content: space-between;
    .data-box {
      height: 80px;
      width: 25%;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: space-between;
      .headings {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #888888;
        img {
          margin-left: 5px;
        }
      }
      .number-data {
        font-size: 14px;
        color: #888888;
        margin: 0px auto;
      }
    }
  }
  .action-head {
    display: flex;
    justify-content: space-between;
    img {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
    .action-dropdown {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #080707;
      float: right;
    }
  }

  .share-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #dededd;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .before-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    line-height: 60px;
    span {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #888888;
      letter-spacing: 0;
      .beforeAt {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #000000;
      }
    }
  }
  .learn-box {
    display: flex;
    align-items: center;
    height: 60px;
    line-height: 60px;
    .linkCam {
      cursor: pointer;
      color: #006dcc;
    }
    .linkCamNo {
      cursor: no-drop;
    }
  }
  .marLeft-30px {
    margin-left: 30px;
  }
  .boder-bot {
    border-bottom: 1px solid #dededd;
  }
  .accountImg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    position: relative;
    .mainImg {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .inimg {
      width: 30px;
      height: 30px;
      position: absolute;
      bottom: 5px;
      left: 20px;
    }
  }
  .action-dropdown {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #080707;
    float: right;
  }
}
</style>
